import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down("xl")]: {
        margin: "50px 15%",
      },
      [theme.breakpoints.down("lg")]: {
        margin: "50px 8%",
      },
      [theme.breakpoints.down("md")]: {
        margin: "50px 5%",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "50px 24px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "50px 24px",
      },
    },
  }),
);
